export class OidcState {
	private kvp: Map<string, string>;

	constructor() {
		this.kvp = new Map<string, string>();
	}

	public fromString(value: string): void {
		this.kvp = new Map<string, string>();
		if (value) {
			var splitValues: string[] = value.split(",");
			
			splitValues.forEach(
				val => {
					var splitVal: string[] = val.split(":");
					if (splitVal.length == 1) {
						this.kvp.set(splitVal[0], "");
					} else if (splitVal.length > 1) {
						this.kvp.set(splitVal[0], splitVal[1]);
					}
				}
			);
		}
	}

	public has(key: string): boolean {
		return this.kvp.has(key);
	}

	public add(key: string, val: string): void {
		this.kvp.set(key, val);
	}

	public get(key: string): string|undefined {
		if (!this.kvp.has(key)) {
			return undefined;
		}

		return this.kvp.get(key);
	}

	public toString(): string {
		return Array.from(this.kvp.keys()).map(k => `${k}:${this.kvp.get(k) ?? ""}`).join();
	}
}