import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Error(props: { imageSrc: string, message: string, buttonText?: string, webDir?: string }) {
	const navigate = useNavigate();

	return (
		<div id="divError" className="divInaccessible">
			<p>{props.message}</p>
			{props.webDir && props.buttonText && props.webDir.length && props.buttonText.length ? 
				<div id='divLoginButton'>
					<Button id='btnLogin' className='loginButton auth-button' variant='outline-primary' type='button' onClick={() => { navigate(`/${props.webDir}` ?? "", { replace: false });}} >{props.buttonText}</Button>
				</div> 
			: null}
			<img src={props.imageSrc} className="errorImage" />
		</div>
	);
}

export default Error;