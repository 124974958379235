import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useOutletContext } from 'react-router-dom';
import { localStorageUtil } from 'utils/local-storage-util';

function Login() {
	
	useEffect(() => {
		localStorageUtil.removeItem("accessToken");
		localStorageUtil.removeItem("refreshToken");
		localStorageUtil.removeItem("interimToken");
	}, []);

	const setLogoSource = useOutletContext();

	return (
		<Container id="divLoginContainer" className="container">
			<div id='divLoginInstructions' className='instructionHeader'>
				<p>THE<i>benefits</i>HUB Login</p>
				<hr />
			</div>
			
			<Outlet context={setLogoSource} />
		</Container>
	);
}

export default Login;
