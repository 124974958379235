export enum AccessLevels {
	// Each level adds upon previous levels
	// so level 3 = levels 3 & 2 & 1
	BT = 1,
	BrokerAndUp = 2,
	CompanyAdminAndUp = 3,
	EmployeeAndUp = 4
}

export function AllowedAccess(setting: AccessLevels, levelToCheck: AccessLevels) {
	return setting >= levelToCheck;
}