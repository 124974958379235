import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.css";
import { ErrorBoundary } from '@allsynx/components';
import { ENVIRONMENT } from 'utils/environment-constants';
import "@allsynx/components/dist/cjs/index.css"

const root = ReactDOM.createRoot(document.getElementById('root')!);
document.title = ENVIRONMENT.toLowerCase().includes("dev") ? document.title = "[Dev] Login Page | THEbenefitsHUB" :
    (ENVIRONMENT.toLowerCase().includes("bravo") ||
    ENVIRONMENT.toLowerCase().includes("charlie") ||
    ENVIRONMENT.toLowerCase().includes("delta") ||
    ENVIRONMENT.toLowerCase().includes("echo") ||
    ENVIRONMENT.toLowerCase().includes("foxtrot")) ? 
    document.title = "[" + ENVIRONMENT.substring(0, 1).toUpperCase() + "]" + " Login Page | THEbenefitsHUB" :
      document.title = "Login Page | THEbenefitsHUB";

root.render(
  <React.StrictMode>
    <ErrorBoundary>
        <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
