import { useNavigate } from "react-router-dom";
import { ENVIRONMENT, urls } from "utils/environment-constants";

function NoWebDirectory () {
	const navigate = useNavigate();
	// when debugging, this is the start page of the app so it needs to not redirect to allsynx.com
	var nonDebugEnvironments: string[] = ["production", "prod", "bravo", "charlie", "delta", "echo"];
    if (nonDebugEnvironments.includes(ENVIRONMENT.toLowerCase())) {
		window.location.href = "https://www.allsynx.com";
	} else {
		navigate(`/admin`, { replace: false });
	}

	return <p>Redirecting...</p>;
}

export default NoWebDirectory;