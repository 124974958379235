import MicrosoftButton from '../../resources/MicrosoftLogin.svg';
import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BorderSpinner } from '@allsynx/components';
import { fetchGraphQlAnonymous } from 'utils/api-util';
import { useNavigate } from 'react-router-dom';
import { UserTypes } from 'enums/user-types';
import { OidcState } from 'classes/oidc-state';
import { MicrosoftSignInInfo } from 'types/microsoft-sign-in-info';


function MicrosoftLogin (props : {companyId: number, isLoading: boolean, setIsLoading: (l: boolean) => void, userType?: UserTypes, nextPage?: string}) {

    const [signInUrl, setSignInUrl] = useState("");
    const companyId = props.companyId;

	const navigate = useNavigate();
	
    async function GetMicrosoftSignInInfo(companyId: number){
        const response = await fetchGraphQlAnonymous<MicrosoftSignInInfo>(
            `query {
				microsoftSignInInfo(companyId: ${companyId}) {
					signInUrl,
					userErrors
				}
			}`,
			"microsoftSignInInfo"
        );

        if (response?.resObj?.signInUrl){
            setSignInUrl(response.resObj.signInUrl);
        } 
    }

    const handleButtonClick = () => {
		props.setIsLoading(true);
        if (signInUrl?.length > 0) {
			var url = signInUrl;
			var state = new OidcState();
			if (props.userType) {
				state.add("UserType", props.userType);
			}
			if (props.nextPage) {
				state.add("NextPage", props.nextPage);
			}

			if (state.toString()) {
				url = `${signInUrl}&state=${state.toString()}`;
			}
            window.location.replace(url);
        } else {
			navigate("/errors/error", { replace: false });
			props.setIsLoading(false);
		}
    }

    useMemo(async () => {
        await GetMicrosoftSignInInfo(companyId);
    }, [companyId]);

    return (
        <>  
            <Button 
                id="btnMicrosoftLogin" 
                className="loginButton oidcLoginButton" 
                onClick={handleButtonClick} 
                type="button" 
            >
                {(props.isLoading) ? 
					<BorderSpinner isFooterSavingSpinner /> 
					: <>
						<img src={MicrosoftButton}></img>
						<span className='oidc-button-text'>Login with Microsoft</span>
					</>}
				
            </Button>
        </>
    );
}

export default MicrosoftLogin;