
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "pages/login/login";
import Health from "pages/health/health";
import OidcRedirect from "pages/oidc/redirect";
import Frame from "components/layout/frame";
import SystemLogin from "pages/login/system-login";
import CompanyLogin from "pages/login/company-login";
import NoWebDirectory from "pages/errors/no-web-directory";
import ErrorPage from "pages/errors/error-page";
import ErrorRobot from 'resources/robots/error.png';
import MaintenanceRobot from 'resources/robots/tools.png';
import ThinkingRobot from 'resources/robots/thinking.svg';
import MfaOptions from "pages/mfa/options";
import MfaVerify from "pages/mfa/verify";
import OidcConnectionTest from "pages/oidc/connection-test";
import TestEeLogin from "pages/login/test-ee-login";
import CompanySettings from "pages/settings/company/company-settings";
import Settings from "pages/settings/settings";
import UserSecuritySettings from "pages/settings/user/user-security-settings";
import OidcAutoLaunch from "pages/oidc/auto-launch";
import NavigationContextProvider from "contexts/navigation-context";

function Router(){
    
    return (
		<BrowserRouter>
			<Routes>
				{/* Unframed (no header/footer) pages go here, need to be first */}
				<Route path="/health" element={<Health />} />

				{/* Framed (with header/footer) pages go here, company login page (/webdir) must be last */}
				<Route element={<NavigationContextProvider><Frame /></NavigationContextProvider>} >
					<Route path="/oidc">
						<Route path="redirect" element={<OidcRedirect />} />
						<Route path="test" element={<OidcConnectionTest />} />
						<Route path="launch" element={<OidcAutoLaunch />} />
					</Route>
					<Route path="/errors">
						<Route index element={<ErrorPage imageSrc={ErrorRobot} message="Oops! Something went wrong. Please contact your system administrator for assistance." />} />
						<Route path="error" element={<ErrorPage imageSrc={ErrorRobot} message="Oops! Something went wrong. Please contact your system administrator for assistance." />} />
						<Route path="not-found" element={<ErrorPage imageSrc={ErrorRobot} message="Oops! Page not found. Please contact your system administrator for assistance." />} />
						<Route path="inactive" element={<ErrorPage imageSrc={ErrorRobot} message="This company is currently inaccessible. Please contact your system administrator for assistance" />} />
						<Route path="inaccessible">
							<Route index element={<ErrorPage imageSrc={MaintenanceRobot} message="This company is currently inaccessible for maintenance. We'll be back soon. Thank you for your patience!" />} />
							<Route path=":webDir" element={<ErrorPage imageSrc={MaintenanceRobot} message="This company is currently inaccessible for maintenance. We'll be back soon. Thank you for your patience!" showTestEmployeeLink={true} />} />
						</Route>
						<Route path="unauthorized">
							<Route index element={<ErrorPage imageSrc={ThinkingRobot} message="Invalid Session.  Please return to the login page." />} />
							<Route path=":webDir" element={<ErrorPage imageSrc={ThinkingRobot} message="Invalid Session.  Please return to the login page by clicking the button below." buttonText="Return to Login Page" />} />
						</Route>
						
					</Route>
					<Route path="/mfa">
						<Route path="options" element={<MfaOptions />} />
						<Route path="verify" element={<MfaVerify />} />
					</Route>
					<Route path="/settings" element={<Settings />}>
						<Route path=":webDir">
							<Route index element={<CompanySettings />} />
							<Route path=":userType/:userId/security" element={<UserSecuritySettings />} />
						</Route>
					</Route>

					<Route path="/" element={<Login />} >
						<Route index element={<NoWebDirectory />} />
						<Route path="admin" element={<SystemLogin />} />
						<Route path=":webDir">
							<Route index element={<CompanyLogin />} />
							<Route path="test" element={<TestEeLogin />} />
						</Route>
					</Route>

					<Route path="*" element={<Navigate to="/errors/not-found" />} />
				</Route>
				

			</Routes>
		</BrowserRouter>
    );
}

export default Router;