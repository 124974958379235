import { useParams } from "react-router-dom";
import { FormTextbox, Switch, NavFooter, HeaderRibbon } from '@allsynx/components';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { CompanyInfo } from "types/company-info";
import { AuthCompanySettingsInfo } from "types/auth-company-settings-info";
import { fetchGraphQlAnonymous, fetchGraphQlAuthenticated } from "utils/api-util";
import { ProviderTypes } from "enums/provider-types";
import { urls } from "utils/environment-constants";
import { MicrosoftSignInInfo } from "types/microsoft-sign-in-info";
import { NavigationContext } from "contexts/navigation-context";
import { validate as isValidUUID } from 'uuid';
import { CompanyRibbonDataInfo } from "types/ribbon-data";

async function getCompanyInfo(webDirectory: string): Promise<CompanyInfo|null> {
	if (webDirectory) {
		const response = await fetchGraphQlAnonymous<CompanyInfo>(
				`query {
						companyInfo(webDirectory:"${webDirectory}") {
							companyId,
							companyName,
							isSelfService
						}
					}`
			, "companyInfo"
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch company info for webDirectory: [${webDirectory}].`);
		return null;
	}
};

async function getAuthCompanySettingsInfo(companyId: number): Promise<AuthCompanySettingsInfo|null> {
	if (companyId) {
		const response = await fetchGraphQlAuthenticated<AuthCompanySettingsInfo>(
				`query {
					authCompanySetting (companyId: ${companyId}) {
						authCompanySetting {
							companyId,
							useNewLogin,
							useMicrosoftSignIn,
							useGoogleSignIn,
							useBasicSignIn,
							useCompanyAdminBasicSignIn	
						},
						providerConfigurations {
							companyId,
							providerType,
							tenantId,
							domainHint
						},
						userErrors
					  }
					}`
			, "authCompanySetting"
			, `${urls.tbhBaseUrl}/menuadmin.cfm?message=You+are+not+authorized+to+access+that+page.`
		);
		
		if (response?.resObj?.userErrors && response.resObj.userErrors.length > 0) {
			if (response.resObj.userErrors.includes("Unauthorized")) {
				window.location.replace(`${urls.tbhBaseUrl}/menuadmin.cfm?message=You+are+not+authorized+to+edit+Authentication+Settings.`);
			} else {
				window.location.replace(`${urls.tbhBaseUrl}/menuadmin.cfm?message=${response.resObj.userErrors.join().replaceAll(" ", "+")}`);
			}
		}

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch company info for companyId: [${companyId}].`);
		return null;
	}
};

async function getRibbonData(companyId: number): Promise<CompanyRibbonDataInfo|null> {

	const response = await fetchGraphQlAuthenticated<CompanyRibbonDataInfo>(
			`query {
					ribbonData(companyId:${companyId}) {
						errors
						companyTags {
							tagValue
							tagDisplay
							tagClipBoardValue
							tagHover
						}
					}
				}`
		, "ribbonData"
		, null
		, `${urls.membersApiBaseUrl}/graphql`
	);

	return response?.resObj ?? null;
};

async function saveAuthCompanySetting(
		companyId: number,
		useMicrosoftSignIn: boolean,
		useGoogleSignIn: boolean,
		useBasicSignIn: boolean,
		useCompanyAdminBasicSignIn: boolean,
		microsoftTenantId: string | null,
		microsoftDomainHint: string | null,
		) {
	if (companyId) {
		const response = await fetchGraphQlAuthenticated<AuthCompanySettingsInfo>(
				`mutation {
				  saveAuthCompanySetting(input: { 
					companyId: ${companyId}
					useMicrosoftSignIn: ${useMicrosoftSignIn}
					useGoogleSignIn: ${useGoogleSignIn}
					useBasicSignIn: ${useBasicSignIn}
					useCompanyAdminBasicSignIn: ${useCompanyAdminBasicSignIn}
					microsoftTenantId: "${microsoftTenantId}"
					microsoftDomainHint: "${microsoftDomainHint ? microsoftDomainHint: ""}"
					}) {
					authCompanySetting {
					  companyId
					  useNewLogin
					  useMicrosoftSignIn
					  useGoogleSignIn
					  useBasicSignIn	
					  useCompanyAdminBasicSignIn				  
					}
					providerConfigurations {
					  providerType
					  tenantId
					  domainHint
					}
					userErrors
				  }
				}`
			, "saveAuthCompanySetting"
			, `${urls.tbhBaseUrl}/menuadmin.cfm?message=You+are+not+authorized+to+access+that+page.`
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch company info for companyId: [${companyId}].`);
		return null;
	}
}

async function saveTenantId(
	companyId: number,
	microsoftTenantId: string | null,
	) {
if (companyId) {
	const response = await fetchGraphQlAuthenticated<AuthCompanySettingsInfo>(
			`mutation {
			  saveAuthCompanySetting(input: { 
				companyId: ${companyId}
				useMicrosoftSignIn: true,
				microsoftTenantId: "${microsoftTenantId}"
				}) {
				authCompanySetting {
				  companyId
				  useNewLogin
				  useMicrosoftSignIn
				  useGoogleSignIn
				  useBasicSignIn					  
				}
				providerConfigurations {
				  providerType
				  tenantId
				  domainHint
				}
				userErrors
			  }
			}`
		, "saveAuthCompanySetting"
		, `${urls.tbhBaseUrl}/menuadmin.cfm?message=You+are+not+authorized+to+access+that+page.`
	);

	return response?.resObj ?? null;
} else {
	console.error(`Could not fetch company info for companyId: [${companyId}].`);
	return null;
}
}

async function getMicrosoftTestUrl(companyId: number): Promise<string> {
	const response = await fetchGraphQlAnonymous<MicrosoftSignInInfo>(
		`query {
			microsoftSignInInfo(companyId: ${companyId}) {
				testUrl,
				userErrors
			}
		}`,
		"microsoftSignInInfo"
	);

	return response?.resObj?.testUrl ?? "";
}

function CompanySettings() {
	const { setSideBarNavItems } = useContext(NavigationContext);
	useEffect(() => {
		setSideBarNavItems([
			{
				id: "systemMenu",
				displayName: "System Menu",
				href: "#",
				onClick: () => {
					var newUrl = `${urls.tbhBaseUrl}/MenuSystem.cfm`;
					window.location.replace(newUrl);
				}
			  },
			  {
				id: "companyMenu",
				displayName: "Company Menu",
				href: "#",
				onClick: () => {
					var newUrl = `${urls.tbhBaseUrl}/MenuAdmin.cfm`;
					window.location.replace(newUrl);
				}
			  },
			])
	}, [])
	const { webDir } = useParams();
	
	const [isLoading, setIsLoading] = useState(false);
	const [isInitialLoading, setIsInitialLoading] = useState(true);
	const [webDirectory, setWebDirectory] = useState("");
	const [disableFooterButtons, setDisableFooterButtons] = useState(false);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfo|null>(null);
	const [settingsInfo, setSettingsInfo] = useState<AuthCompanySettingsInfo|null>(null);
	const [ribbonDataInfo, setRibbonDataInfo] = useState<CompanyRibbonDataInfo|null>(null);
	const [isBasicEnabled, setIsBasicEnabled] = useState(false);
	const [isCompanyAdminBasicEnabled, setIsCompanyAdminBasicEnabled] = useState(false);
	const [isMicrosoftEnabled, setIsMicrosoftEnabled] = useState(false);
	const [isGoogleEnabled, setIsGoogleEnabled] = useState(false);
	const [msTenantId, setMsTenantId] = useState("");
	const [msTenantIdError, setMsTenantIdError] = useState("");
	const [msDomainHint, setMsDomainHint] = useState("");
	const [msDomainHintError, setMsDomainHintError] = useState("");
	const [microsoftTestUrl, setMicrosoftTestUrl] = useState("");
	const [isMsTenantDirty, setIsMsTenantDirty] = useState(true);

	useEffect(() => {
		loadPageData(webDir).catch(console.error);
	}, [webDir]);

	const loadPageData = async (webDirectoryParam: string | undefined) => {
		setDisableFooterButtons(true);

		setCompanyInfo(null);
		setMicrosoftTestUrl("");
		setSettingsInfo(null);
		setIsBasicEnabled(false);
		setIsMicrosoftEnabled(false);
		setIsGoogleEnabled(false);
		setMsTenantId("");
		setMsDomainHint("");

		if (webDirectoryParam) {
			setWebDirectory(webDirectoryParam);

			// load general company info
			const info = await getCompanyInfo(webDirectoryParam);	
			if (info) {
				setCompanyInfo(info);

				const [settingsInfo, ribbonData] = await Promise.all([getAuthCompanySettingsInfo(info.companyId), getRibbonData(info.companyId)])

				if (ribbonData) {
					setRibbonDataInfo(ribbonData)
				}

				if (settingsInfo) {
					setSettingsInfo(settingsInfo);
					setIsBasicEnabled(settingsInfo.authCompanySetting?.useBasicSignIn ?? false);
					setIsCompanyAdminBasicEnabled(settingsInfo.authCompanySetting?.useCompanyAdminBasicSignIn ?? false);
					setIsMicrosoftEnabled(settingsInfo.authCompanySetting?.useMicrosoftSignIn ?? false);
					setIsGoogleEnabled(settingsInfo.authCompanySetting?.useGoogleSignIn ?? false);

					if (settingsInfo.authCompanySetting?.useMicrosoftSignIn ?? false)
					{
						// load ms test url
						const msTestUrl = await getMicrosoftTestUrl(info.companyId);
						setMicrosoftTestUrl(msTestUrl);
					}

					const pConfig = settingsInfo.providerConfigurations.find(o => o.providerType == ProviderTypes.Microsoft);
					if (pConfig)
					{						
						setIsMsTenantDirty(false);
						setMsTenantId(pConfig.tenantId);
						setMsDomainHint(pConfig.domainHint);
					}
					else
					{
						setIsMsTenantDirty(true);
					}
				}

				setIsInitialLoading(false);
			}
		} else {
			console.error(`no webDirectoryParam [${webDirectoryParam}]`);
		}
		setDisableFooterButtons(false);
	};

	const handleSubmit = async (e: FormEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setIsLoading(true);
		setDisableFooterButtons(true);

		if (companyInfo?.companyId && validateDomainHint() && validateTenantId()) {
			const resp = await saveAuthCompanySetting(companyInfo?.companyId, isMicrosoftEnabled, isGoogleEnabled, isBasicEnabled, isCompanyAdminBasicEnabled, isMicrosoftEnabled ? msTenantId : null, isMicrosoftEnabled ? msDomainHint : null);
			if (resp !== null && resp.userErrors.length == 0)
			{
				var newUrl = `${urls.tbhBaseUrl}/MenuAdmin.cfm?Message=Authentication+Settings+saved.`;
				window.location.replace(newUrl);
			} else {
				setMsTenantIdError(resp?.userErrors[0] ?? "");
				setDisableFooterButtons(false);
				setIsLoading(false);
			}
		} else {
			console.error(`Unable to submit form, invalid or missing companyId: [${companyInfo?.companyId}]`);
			setDisableFooterButtons(false);
			setIsLoading(false);
		}
	};

	const handleSaveTenantId = async (e: FormEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setIsLoading(true);
		setDisableFooterButtons(true);

		if (companyInfo?.companyId && validateTenantId()) {
			const resp = await saveTenantId(companyInfo?.companyId, msTenantId);
			if (resp !== null && resp.userErrors.length == 0)
			{
				const msTestUrl = await getMicrosoftTestUrl(companyInfo?.companyId);
				setMicrosoftTestUrl(msTestUrl);
				setIsMsTenantDirty(false);
			}
			else
			{
				setMsTenantIdError(resp?.userErrors[0] ?? "");
			}
		} else {
			console.error(`Unable to submit form, invalid or missing companyId: [${companyInfo?.companyId}]`);
		}
		setDisableFooterButtons(false);
		setIsLoading(false);
	}

	const handleTestConnection = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)  =>   {
		if (isMicrosoftEnabled && microsoftTestUrl && !isMsTenantDirty) {
			window.open(microsoftTestUrl, '_blank')?.focus();
		} else {
			console.log(`isMicrosoftEnabled: [${isMicrosoftEnabled}], microsoftTestUrl: [${microsoftTestUrl}]`);
		}
	}

	const handleCopyUrl = async (e: FormEvent<HTMLAnchorElement>) => {
		e.preventDefault();

		if (isMicrosoftEnabled && microsoftTestUrl  && msTenantId && !isMsTenantDirty) {
			await navigator.clipboard.writeText(microsoftTestUrl);
			alert('Url copied to clipboard.');
		} else {
			console.log(`isMicrosoftEnabled: [${isMicrosoftEnabled}], microsoftTestUrl: [${microsoftTestUrl}]`);
		}
	}

	const validateTenantId = (): boolean => {
		// MS emabled requires tenant id
		if (isMicrosoftEnabled) {
			if (!msTenantId) {
				setMsTenantIdError("Tenant ID field is required");
				return false;
			}

			const guidRegex = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
			if (!guidRegex.test(msTenantId)) {
				setMsTenantIdError("Please provide a valid Tenant ID");
				return false;
			}
			if(!isValidUUID(msTenantId)) {
				setMsTenantIdError("Please provide a valid Tenant ID");
				return false;
			}
			if(msTenantId == "00000000-0000-0000-0000-000000000000") {				
				setMsTenantIdError("Please provide a valid Tenant ID");
				return false;
			}
		}
		setMsTenantIdError("");
		return true;
	}
	const validateDomainHint = (): boolean => {
		if (isMicrosoftEnabled) {
			// domain hint is optional, but if provided must be a domain (with allowed subdomains)
			if (msDomainHint) {
				const domainRegex = /^([a-zA-Z0-9\-]{2,}\.)+[a-zA-Z0-9\-]{2,}$/
				if (!domainRegex.test(msDomainHint)) {
					setMsDomainHintError("Please provide a valid Domain Hint");
					return false;
				}
			}
		}
		setMsDomainHintError("");
		return true;
	}

	const renderView = () => {
		if (!companyInfo || isInitialLoading) {
			return (<></>);
		}

		return (
				<div className="settings-outer">
					{ribbonDataInfo && ribbonDataInfo.companyTags.length > 0 && <HeaderRibbon.Container>
						<HeaderRibbon companyRibbon={{
							tags: ribbonDataInfo.companyTags.map(tag => ({...tag, tagClipboardValue: tag.tagClipBoardValue})),
							selectEmployeeLink: urls.employeePick,
							changeCompanyLink: urls.companyPick
						}}/>
					</HeaderRibbon.Container>}
					<h3>Authentication Settings</h3>
					<div className="settings-inner">
						<p className="toggle-header">Use Employee Basic Authentication</p>
						<Switch id="chkEmployeeBasic" switchType="YesNo" checked={isBasicEnabled} onChange={(e) => { setIsBasicEnabled(e.target.checked);}}/>

						<p className="toggle-header">Use Company Administrator Basic Authentication</p>
						<Switch id="chkCompanyAdminBasic" switchType="YesNo" checked={isCompanyAdminBasicEnabled} onChange={(e) => { setIsCompanyAdminBasicEnabled(e.target.checked);}}/>

						<p className="toggle-header">Use Microsoft Authentication</p>
						<Switch id="chkMicrosoft" switchType="YesNo" checked={isMicrosoftEnabled} onChange={(e) => { setIsMicrosoftEnabled(e.target.checked); }}/>
						{ isMicrosoftEnabled ? 
						<div id="MicrosoftFields">
							<div id="tenantGroup">
								<FormTextbox
									type={"text"}
									placeholder={"00000000-0000-0000-0000-000000000000"}
									id="tbTenantId"
									name={"tenantId"}
									label="Tenant ID"
									className="settings-text-box"
									value={msTenantId}
									onChange={(e) => { setIsMsTenantDirty(true);  setMsTenantId(e.target.value); setMsTenantIdError(""); }}
									onBlur={() => validateTenantId()}
									fieldWidth="full"
									/>
								<Button id="btnApplyTenant" className="button-settings-sub" disabled={!isMsTenantDirty} onClick={handleSaveTenantId}>Apply ID</Button>
								{msTenantIdError.length > 0 ? <div id="msTenantIDError" style={{"display": "block"}} className="text-box-error">{msTenantIdError}</div> : null}
							</div>
							<FormTextbox
								type={"text"}
								placeholder={"example.com"}	
								id="tbDomainHint"
								name={"domainHint"}
								label="Domain Hint"
								optional={true}
								className="settings-text-box"
								value={msDomainHint}
								onChange={(e) => { setMsDomainHint(e.target.value); }}
								onBlur={() => validateDomainHint()}
								fieldWidth="full"
								/>
							{msDomainHintError.length > 0 ? <div id="msDomainIdError" style={{"display": "block"}} className="text-box-error">{msDomainHintError}</div> : null}
							<Button id="btnTestLink" className="button-settings" disabled={isMsTenantDirty} onClick={handleTestConnection}>Test Connection</Button>
							<a id="lnkTestLinkCopy" className={isMsTenantDirty ? "copy-test-label-disabled": "copy-test-label"} href="#" onClick={handleCopyUrl} >Copy Test Link</a>	
						</div>
						:
						<br/>			
						}
						
				<NavFooter
					className="settings-nav"
					leftButtons={
						<>
							<NavFooter.Button id="btnBack" buttonType="Back" disabled={disableFooterButtons} href={`${urls.tbhBaseUrl}/MenuAdmin.cfm`}/>
							<NavFooter.Button id="btnSave" buttonType="Blue" disabled={disableFooterButtons} onClick={handleSubmit} href="/">
								Save
							</NavFooter.Button>
						</>
					}
					/>	
					</div>	
				</div>	
		); }

		return (
			<>
				{renderView()}
			</>
		  );
}

export default CompanySettings;