export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;
export const APP_SERVER_NAME = process.env.REACT_APP_APP_SERVER_NAME as string;

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_AUTHENTICATION_BASE_URL =
  process.env.REACT_APP_API_AUTHENTICATION_BASE_URL;
const API_COMPANIES_BASE_URL = process.env.REACT_APP_API_COMPANIES_BASE_URL;
const API_MEMBERS_BASE_URL = process.env.REACT_APP_API_MEMBERS_BASE_URL;
const IDENTITYSERVER_BASE_URL = process.env.REACT_APP_IDENTITYSERVER_BASE_URL;

export const urls = {
  tbhBaseUrl: BASE_URL,
  loginRedirect: BASE_URL + "/LoginHandoff.cfm",
  apiBaseUrl: API_AUTHENTICATION_BASE_URL,
  companiesApiBaseUrl: API_COMPANIES_BASE_URL,
  membersApiBaseUrl: API_MEMBERS_BASE_URL,
  identityServerBaseUrl: IDENTITYSERVER_BASE_URL,
  companyPick: BASE_URL + "/Company/CompanyPick.cfm?Action=Edit",
  employeePick: BASE_URL + "/Employees/EmployeePick.cfm?Action=Edit",
  employeeJournalAddEdit: BASE_URL + "/Employees/EmployeeJournalAddEdit.cfm",
};
