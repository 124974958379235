import { getAnonymous, postAnonymous } from 'utils/api-util';
import { useNavigate } from 'react-router-dom';
import { UserTypes } from 'enums/user-types';
import { OidcState } from 'classes/oidc-state';
import { useEffect } from 'react';


function OidcAutoLaunch () {

	const navigate = useNavigate();
	
	useEffect(() => {
		const GetMicrosoftUrl = async () => {
			const response = await getAnonymous<string>('/api/oidc/microsoft/url');

			if (response?.resObj) {
				var url = response?.resObj;
				var state = new OidcState();

				if (state.toString()) {
					url = `${url}&state=${state.toString()}`;
				}
				window.location.replace(url);
			} else {
				navigate("/errors/error", { replace: false });
			}
		}

		GetMicrosoftUrl();
	}, []);


    return (
        <>  
            <p>Processing request...</p>
        </>
    );
}

export default OidcAutoLaunch;