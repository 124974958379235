export enum UserTypes {
	Employee = "EMPLOYEE",
	CompanyAdmin = "COMPANY_ADMIN",
	SystemAdmin = "SYSTEM_ADMIN"
}

export function GetUserTypeNumeric(userType: UserTypes | null) {
	if (userType === UserTypes.Employee) return 0;
	if (userType === UserTypes.CompanyAdmin) return 1;
	if (userType === UserTypes.SystemAdmin) return 2;

	return -1;
}