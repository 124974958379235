import { BorderSpinner } from "@allsynx/components";
import { Button } from "react-bootstrap";
import GoogleButton from "../../resources/GoogleLogin.svg";
import { useNavigate } from "react-router-dom";
import { UserTypes } from "enums/user-types";

function GoogleLogin (props: {companyId: number, isLoading: boolean, setIsLoading: (l: boolean) => void, userType?: UserTypes, nextPage?: string}) {
	const navigate = useNavigate();

	const handleButtonClick = () => {
		navigate("/errors/error", { replace: false });
    }

	return (
			<Button id="btnGoogleLogin" className="loginButton oidcLoginButton"  onClick={handleButtonClick} type="button">
				{(props.isLoading) ?
					<BorderSpinner isFooterSavingSpinner />
					: <>
						<img src={GoogleButton}></img>
						<span className="oidc-button-text">Login with Google</span>
					</>}
			</Button>
	);
}

 export default GoogleLogin;