import jwtDecode from 'jwt-decode';
import { DEBUG_JWT } from './debug-jwt';
import { ENVIRONMENT } from './environment-constants';
import { localStorageUtil } from './local-storage-util';
import { getAnonymous } from './api-util';

const DEFAULT_UNAUTHORIZED_REDIRECT = "/errors/unauthorized";

export const isExpired = (token: string): boolean => {
	if (!token) {
		return true;
	}

	try {
        const { exp } = jwtDecode(token) as {
            exp: number;
        };

        return Date.now()/1000 >= exp;
    } catch {
        return true;
    }
};

export async function getTokenClaim(claimType: string): Promise<string|null> {
	var token = await tryGetToken("");

	try {
        const jwt: any = jwtDecode(token);
        if(!(claimType in jwt)) {
            return null;
        }
        var value = jwt[claimType];
        if(Array.isArray(value))
        {
            return value.join(',');
        }
        return value.toString();
    } catch {
        return null;
    }
};

export async function tryGetToken(unauthorizedRedirect: string|null): Promise<string> {
	var accessToken = "";
	// for local debugging, get a jwt token from the hub (INTERIM_TOKEN or ACCESS_TOKEN cookies)
	// and save to the /Utilities/debug-jwt.ts file
	if (DEBUG_JWT !== "" && (ENVIRONMENT === "" || ENVIRONMENT === "__"+"ENVIRONMENT__")) {
		console.log('Using Debug Jwt');
		accessToken = DEBUG_JWT;
		if (isExpired(accessToken)) {
			console.log('Debug Jwt expired');
			throw "Debug Token is expired!";
		}
	} else {
		// check if access token exists in storage
		accessToken = localStorageUtil.getItem("accessToken") ?? "";
		console.log(`Loading token from storage [${accessToken}]`);
		if (!accessToken) {
			console.log(`No token, try hydrating`);
			// no stored access token  - try hydrate
			accessToken = await tryHydrate();
			console.log(`tryHydrate complete [${accessToken}]`);

			if (!accessToken) {
				console.log(`No token after hydrating, try refresh`);
				// try to use refresh token
				var refreshSuccessful = await tryRefresh();
				console.log(`tryRefresh complete [${refreshSuccessful}]`);

				// refreshing creates new cookies that need to be hydrated
				if (refreshSuccessful) {
					console.log(`refresh success, try hydrating`);
					accessToken = await tryHydrate();
					console.log(`tryHydrate complete [${accessToken}]`);

					// make sure refresh provided good valid tokens
					if (!accessToken || isExpired(accessToken)) {
						console.log(`new access token null or expired, send to login`);
						redirectTo(unauthorizedRedirect);
					}
				} else {
					console.log(`refresh unsuccessful, send to login`);
					redirectTo(unauthorizedRedirect);
				}
			}
		}

		// check if token is expired
		if (isExpired(accessToken)) {
			console.log(`token expired`);
			// try to use refresh token
			var refreshSuccessful = await tryRefresh();
			console.log(`tryRefresh complete [${refreshSuccessful}]`);

			// refreshing creates new cookies that need to be hydrated
			if (refreshSuccessful) {
				console.log(`refresh success, try hydrating`);
				accessToken = await tryHydrate();
				console.log(`tryHydrate complete [${accessToken}]`);

				// make sure refresh provided good valid tokens
				if (!accessToken || isExpired(accessToken)) {
					console.log(`new access token null or expired, send to login`);
					redirectTo(unauthorizedRedirect);
				}
			}
		}
	}
	return accessToken ?? "";
}

async function tryHydrate(): Promise<string> {
	const response = await getAnonymous<{accessToken: string, interimToken: string}>('/api/tokens/hydrate');
		
	if (response?.ok && response?.status !== 204) {
		const json = await response?.resObj;
		
		// default to access token
		if (json?.accessToken) {
			localStorageUtil.storeItem("accessToken", json.accessToken);
			return json.accessToken;
		}
	}
	else
	{
		console.error(`Error hydrating tokens, received status [${response?.status}].`);
		const responseJson = await response?.resObj;
		console.error(JSON.stringify(responseJson));
	}

	return "";
}

async function tryRefresh(): Promise<boolean> {
	const response = await getAnonymous<string>('/api/tokens/refresh');
	console.log(`tryRefresh response: [${response?.status}: ${JSON.stringify(response)}]`);

	if (!response?.ok) {
		console.log(`attempting tryRefresh again`);

		// wait and try again
		var waitDelay = Math.floor(Math.random()*500)+750; // random delay between 750ms-1.25s
		await new Promise(resolve => setTimeout(resolve, waitDelay));

		const response = await getAnonymous<string>('/api/tokens/refresh');
		console.log(`tryRefresh attempt 2 response: [${response?.status}: ${JSON.stringify(response)}]`);
	}

	return response?.ok ?? false;
}

function redirectTo(unauthorizedRedirect: string|null) {
	// null - default url, blank string - stay on page no redirect, other - go to value
	if (unauthorizedRedirect == null) {
		window.location.replace(DEFAULT_UNAUTHORIZED_REDIRECT);
	} else if (unauthorizedRedirect.length < 1) {
		return;
	} else {
		window.location.replace(unauthorizedRedirect);
	}
}