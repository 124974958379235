import './App.css';
import Router from 'routes/router';

function App() {
	var webDir = localStorage.getItem('webDir');
	localStorage.clear();
	if (webDir) {
		localStorage.setItem('webDir', webDir);
	}

	return (
		<Router />
	);
}

export default App;
