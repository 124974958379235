import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import SuccessRobot from '../../resources/robots/check.svg'
import ThinkingRobot from '../../resources/robots/thinking.svg';
import { getAnonymous, postAnonymous } from "utils/api-util";
import { BorderSpinner } from "@allsynx/components";

function OidcConnectionTest () {
	const [searchParams, setSearchParams] = useSearchParams();
	const { hash } = useLocation();
	const [idToken, setIdToken] = useState("");
	const [oidcError, setOidcError] = useState("");
	const [validationError, setValidationError] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const tryCheckTenant = async (token: string) => {
		setIsLoading(true);
		if(token) {
			var response = await getAnonymous<boolean>(`/api/oidc/checkTenant/${token}`);	
			if(response?.ok) {
				var result = response.resObj as boolean;
				if (!result){
					setValidationError("Connection to Microsoft was successful, but Tenant Id from Microsoft credentials did not match Tenant Id setup for the company.");
				}
			}
			else {						
				setValidationError("Connection to Microsoft was successful, but Tenant Id from Microsoft credentials did not match Tenant Id setup for the company .");
			}
		}
		else if(!oidcError) {
			setOidcError("Token not found");
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (searchParams?.get("id_token")) {
			var token: string = searchParams?.get("id_token") ?? "";
			setIdToken(token);
			if(token) {
				tryCheckTenant(token).catch(console.error);
			}
		}

		if (searchParams?.get("error_description")) {
			var errorDescription: string = searchParams?.get("error_description") ?? "";
			setOidcError(decodeURIComponent(errorDescription));
		}
	}, [searchParams]);

	useEffect(() => {
		if (hash) {
			var token = "";

			var hashFragment = hash;
			hashFragment = hashFragment.replaceAll("#", "");
			var splitHashFragment = hashFragment.split("&");
			
			splitHashFragment.forEach((item: string) => {
				var kvp = item.split("=");
				if (kvp.length == 2 && kvp[0] == "id_token") {
					token = kvp[1];
					setIdToken(token)
				} else if (kvp.length == 2 && kvp[0] == "error_description") {
					setOidcError(decodeURIComponent(kvp[1]));
				}
			});

			tryCheckTenant(token).catch(console.error);
		}
		else {
			setOidcError("Token not found");
		}
	}, [hash]);


	return (
		<>
			<div id="divContainer" className="container">
            	<div id='divInstructions' className="instructions divForm">
					<div className='instructionHeader'>
						<p>Provider Connection Test</p>
						<hr/>
					</div>
					{
						!isLoading ? 
							<div>
								{ !validationError ? 
										<div>
											{(oidcError || !idToken) ?
												<div className="oidc-error"><p className="p-center">It looks like the connection was unsuccessful.</p><br /><p className="p-center">Provider Error: [{oidcError}]</p><img src={ThinkingRobot}/></div>
												: <div className="oidc-error"><p className="p-center">Connection was successful!</p><img src={SuccessRobot}/></div>
											}
										</div>
									:
										<div className="oidc-error"><p className="p-center">{validationError}</p><img src={ThinkingRobot}/></div>

								}
							</div>
						:
						<div><BorderSpinner isFooterSavingSpinner/></div>
					}

				</div>
			</div>
			
		</>
	);
}

export default OidcConnectionTest;