import { useEffect, useState } from 'react';
import UsernamePasswordLoginForm from 'components/login/username-password-login-form';
import { localStorageUtil } from 'utils/local-storage-util';
import { useOutletContext } from 'react-router-dom';

function SystemLogin() {
	const [isLoading, setIsLoading] = useState(false);

	const setWebDirForLogo = useOutletContext<(src: string) => void>();

	useEffect(() => {
		localStorageUtil.storeItem('webDir', 'admin');
		setWebDirForLogo("admin");
	}, []);
  
	return(
		<UsernamePasswordLoginForm webDirectory='admin' isLoading={isLoading} setIsLoading={setIsLoading} />
	);
}

export default SystemLogin;