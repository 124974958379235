import React, { createContext, useState } from "react";
import { NavigationContextProviderTypes } from "types/navigation/navigation-context-provider-types";
import { NavigationContextType } from "types/navigation/navigation-context-type";

//TODO: Rework to be less obtuse, more concise. Possibly rename these variables or see if they are necessary at all.
const initialArray = (array: any[]) => {};
const initialBool = (boolean: false) => false;

export const NavigationContext = createContext<NavigationContextType>({
    sideBarNavItems: [],
    footerHasSideBar: false,
    setSideBarNavItems: () => initialArray([]),
    setFooterHasSideBar: () => initialBool
});

export default function NavigationContextProvider({
  children,
}: NavigationContextProviderTypes) { 
  const [sideBarNavItems, setSideBarNavItems] = useState([
    {
        id: "systemMenu",
        displayName: "System Menu"
      },
      {
        id: "companyMenu",
        displayName: "Company Menu"
      },
    ]);    
  const [footerHasSideBar, setFooterHasSideBar] = useState(false);
  return (
    <NavigationContext.Provider
      value={{
        sideBarNavItems,
        footerHasSideBar,
        setSideBarNavItems,
        setFooterHasSideBar
      }}
    >
      <>
        {children}        
      </>
    </NavigationContext.Provider>
  );
}