import { BorderSpinner, FormTextbox} from '@allsynx/components';
import { FormEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HubLoginResponse } from 'types/hub-login-response';
import { postAnonymous } from 'utils/api-util';
import { urls } from 'utils/environment-constants';

function UsernamePasswordLoginForm(props: { webDirectory: string, isLoading: boolean, setIsLoading: (l: boolean) => void, nextPage?: string }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isUsernameTouched, setIsUsernameTouched] = useState(false);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");

  const navigate = useNavigate();

  const validate = (ignoreTouched: boolean): boolean => {
	var isValid = true;
	if (username === "" && (isUsernameTouched || ignoreTouched)) {
		setUsernameError("The Username field is required");
		setIsUsernameValid(false);
		isValid = false;
	} else {
		setIsUsernameValid(true);
		setUsernameError("");
	}

	if (password === "" && (isPasswordTouched || ignoreTouched)) {
		setPasswordError("The Password field is required");
		setIsPasswordValid(false);
		isValid = false;
	} else {
		setIsPasswordValid(true);
		setPasswordError("");
	}

	return isValid;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
	e.preventDefault();
	e.stopPropagation();

	setIsUsernameTouched(true);
	setIsPasswordTouched(true);

	if (validate(true)) {
		setLoginError("");
		props.setIsLoading(true);

		try {
			var urlencoded = new URLSearchParams();
			urlencoded.append("Username", username);
			urlencoded.append("Password", password);
			urlencoded.append("WebDirectory", props.webDirectory);
			urlencoded.append("returnUrl", "");

			const response = await postAnonymous<HubLoginResponse>("/api/login", urlencoded);

			if (response?.ok && response?.resObj?.success && !response?.resObj?.needsMfa && (!response.resObj.errors || response.resObj.errors.length < 1)) {
				var newLocation = `${urls.loginRedirect}?LoginToken=${response.resObj.token}&AuthType=${encodeURIComponent("Admin Authentication")}`;
				if (props.nextPage) {
					newLocation += `&NextPage=${props.nextPage}`;
				}
				window.location.href = newLocation;
			} else if (response?.resObj?.needsMfa) {
				var navLocation = "/mfa/options";
				if (props.nextPage) {
					navLocation += `?NextPage=${props.nextPage}`;
				}
				navigate(navLocation, { replace: false });
			}

			if (response?.resObj?.errors && response.resObj.errors.length > 0) {
				setLoginError(response.resObj.errors);
				props.setIsLoading(false);
			}
			
		} catch (error) {
			console.error(error);
			setLoginError("Error logging in. Please try again.");
			props.setIsLoading(false);
		}
	}

  };

  useEffect(()=>{
    document.getElementById("tbUsername")?.focus();
  }, []);

  return(
	<Form id="divLoginForm" className="divForm" onSubmit={handleSubmit} noValidate>
		<div id="divFormFields">
			<FormTextbox
				type={"text"}
				placeholder={"Username"}
				id={"tbUsername"}
				name="username"
				onChange={(e) => {setUsername(e.target.value);setIsUsernameTouched(true);validate(false);}}
				value={username}
				label="Username"
				isLabelHidden={true}
				isInvalid={!isUsernameValid}
				errorMessage={usernameError}
				touched={isUsernameTouched}
				onBlur={() => validate(false)}
				disabled={props.isLoading}
				fieldWidth="full"
			/>
			<div className="PasswordWrapper">
				<FormTextbox 
				type={isShowPassword ? "text" : "password"}
				placeholder={"Password"}
				id={"tbPassword"}
				name="password"
				onChange={(e) => {setPassword(e.target.value);setIsPasswordTouched(true);validate(false);}}
				value={password}
				label="Password"
				isLabelHidden={true}
				isInvalid={!isPasswordValid}
				onBlur={() => validate(false)}
				errorMessage={passwordError}
				touched={isPasswordTouched}
				disabled={props.isLoading}
				fieldWidth="full"
				/>
				<div className= {isShowPassword ? 'Visibility-On' : 'Visibility-Off' } onClick={() => setIsShowPassword(!isShowPassword)}></div>
			</div>
			<div id='divLoginButton'>
				<Button id='btnLogin' className='auth-button' variant='outline-primary' type='submit' disabled={props.isLoading}>{(props.isLoading) ? <BorderSpinner isFooterSavingSpinner/> : "Login"}</Button>
			</div> 
		</div>
		{loginError.length > 0 ? <div id="divLoginErrorMessage" className="Errors">{loginError}</div> : null}
	</Form>
  );
}

export default UsernamePasswordLoginForm;