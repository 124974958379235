export const localStorageUtil = {
    getItem<T>(key: string): T | undefined {
        const stored = localStorage.getItem(key);
        return stored == null ? undefined : JSON.parse(stored);
    },
    storeItem(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key: string) {
        localStorage.removeItem(key);
    },
    clearAll() {
        localStorage.clear();
    }
};