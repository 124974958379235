import { HeaderNavbar, MainFooter } from "@allsynx/components";
import { Outlet, useMatch } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { CompanyInfo } from "types/company-info";
import { fetchGraphQlAnonymous } from "utils/api-util";
import { localStorageUtil } from "utils/local-storage-util";
import { NavigationContext } from "contexts/navigation-context";
import { APP_SERVER_NAME } from "utils/environment-constants";
import { urls } from "utils/environment-constants";
import { getTokenClaim } from "utils/jwt-util";

function Frame() {
  const { footerHasSideBar } = useContext(NavigationContext);
  const [companyLogoSource, setCompanyLogoSource] = useState<string | undefined>(undefined);
  const [isCompanyLogoSourceLoading, setIsCompanyLogoSourceLoading] = useState(true)
  const [userName, setUserName] = useState("")

  // if user is visiting settings pages, display logout link
  const matchCompanySettings = useMatch("/settings/:webDir");
  const matchUserSettings = useMatch("/settings/:webDir/:userType/:userId/security");
  const isLogoutLinkDisplayed = Boolean(matchCompanySettings || matchUserSettings)

  useEffect(() => {
    if (isLogoutLinkDisplayed) {
      getTokenClaim("sub").then((claimValue) => {
        setUserName(claimValue || "")
      })
    }
  }, [isLogoutLinkDisplayed])

  const setWebDirForLogo = (webDirectory: string) => {
    // if passed as empty or null, check local storage
    if (!webDirectory) {
      const localWebDir = localStorageUtil.getItem<string>("webDir");

      if (localWebDir) {
        setWebDirForLogo(localWebDir);
      } else {
        setCompanyLogoSource(undefined);
        setIsCompanyLogoSourceLoading(false);
      }
    } else {
      if (webDirectory.toLowerCase() === "admin") {
        setCompanyLogoSource(undefined);
        setIsCompanyLogoSourceLoading(false);
      } else {
        fetchGraphQlAnonymous<CompanyInfo>(
          `query {
            companyInfo(webDirectory:"${webDirectory}") {
              companyId
              }
              }`,
              "companyInfo"
            ).then((response) => {
              if (response?.resObj?.companyId) {
                setCompanyLogoSource(
                  `${urls.companiesApiBaseUrl}/api/companylogo/${response.resObj.companyId}`
                );
              }
              setIsCompanyLogoSourceLoading(false);
        });
      }
    }
  };

  const logout = () => {
		localStorageUtil.clearAll();
		window.location.replace(urls.tbhBaseUrl + "/Logout.cfm");
	}

  return (
    <>
      <HeaderNavbar centerElement={!isCompanyLogoSourceLoading && <HeaderNavbar.Logo companyLogoSrc={companyLogoSource}/>} showImageOnSmallWidth={true} 
        rightElement={isLogoutLinkDisplayed ?
                 (
                  <HeaderNavbar.Link
                    id="logoutLink"
                    href="#"
                    link="Logout"
                    userDisplayName={userName}
                    onClick={(e) => {
                      e.preventDefault();
                      logout()
                    }}
                  />
                ) : undefined
              }/>
      <Outlet context={setWebDirForLogo} />
      <MainFooter hasSidebar={footerHasSideBar} hostname={APP_SERVER_NAME} />
    </>
  );
}

export default Frame;
